<template>
  <div class="dis-charts">
    <div class="dis-name">{{ name }}</div>
    <div class="dis-level">
      <p>潛在疾病風險：
        <span v-if="level === 1" class="color-level-1">低風險</span>
        <span v-else-if="level === 2" class="color-level-2">中風險</span>
        <span v-else-if="level === 3" class="color-level-3">高風險</span>
        <span v-else-if="level === 4" class="color-level-4">極高風險</span>
      </p>
    </div>
    <div class="dis-content">
      <div class="dis-bar">
        <div class="bar-box">
          <div class="bar-line">
            <div class="bar-item" v-for="(range, index) in ranges" :key="range" :data-value="range"
              :style="{ backgroundColor: colors[index] }"></div>
            <div class="bar-pointer"
              :style="{ backgroundColor: colors[level - 1], left: getPointerPosition(value), color: colors[level - 1], boxShadow: `0 0 0.1rem ${colors[level - 1]}` }"
              :data-value="value">
            </div>
          </div>
        </div>
        <div class="dis-tip" v-if="level === 2">
          建議每半年追蹤檢查評估
        </div>
        <div class="dis-tip" v-else-if="level === 3">
          建議進一步檢查及每半年追蹤檢查評估
        </div>
        <div class="dis-tip" v-else-if="level === 4">
          建議進一步檢查及每半年追蹤檢查評估
        </div>
        <div class="dis-tip" v-else></div>
      </div>
    </div>

  </div>
</template>



<script>


export default {
  name: "disChart",
  data () {
    return {
      level: 1,
      colors: ["#3FD845", "#FF822F", "#DD3E3C", "#6C0AB1"]
    }
  },
  props: {
    name: {
      default: () => "疾病名称",
      type: String
    },
    ranges: {
      default: () => [],
      type: Array
    },
    value: {
      default: () => 0,
      type: Number
    }
  },
  computed: {
    pointerPosition () {
      return 0
    },
  },
  methods: {
    getPointerPosition (val) {
      let level = 1
      const step = 100 / this.ranges.length
      const ranges = [
        ...this.ranges,
        2000
        // this.ranges[this.ranges.length - 1] * 2
      ]
      let last = ranges[0] || 0
      let rate = 0


      for (let i = 0; i < ranges.length; i++) {
        if (val >= last && val < ranges[i]) {
          level = i
          rate = Number(((val - last) / (ranges[i] - last)).toFixed(2))
          break
        } else {
          last = ranges[i]
        }
      }

      this.level = level

      const position = step * (level - 1) + rate * step



      return `${position}%`
    },
  },
}
</script>

<style lang="scss" scoped>
.color-level-1 {
  color: #3FD845;
}

.color-level-2 {
  color: #FF822F;
}

.color-level-3 {
  color: #DD3E3C;
}

.color-level-4 {
  color: #6C0AB1;
}


.dis-charts {
  position: relative;
  width: 100%;
  padding: .24rem 0;

  >div {
    position: relative;
    width: 100%;
    padding: 0 .24rem;
  }

  .dis-name {
    font-size: 0.36rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: 0.12rem;
    color: #333;

    &::before {
      content: '';
      width: 0.24rem;
      height: 0.2rem;
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
      background-color: #5272ff;
      margin-right: 0.2rem;
    }
  }


  .dis-level {
    font-size: .28rem;
    font-weight: 500;
    color: #333;
    line-height: .4rem;
    margin: .16rem 0;
  }

  .dis-content {
    .dis-bar {
      background-color: #F4F6FC;
      padding: .24rem;
      border-radius: .08rem;

      >div {
        position: relative;
        width: 100%;
      }

      .dis-tip {
        margin-top: .3rem;
        font-size: .28rem;
        font-weight: 400;
        color: #3b4549;
      }

      .bar-box {

        padding: 0.56rem 0 0.44rem;

        >div {
          position: relative;
          width: 100%;

        }

        ;

        .bar-line {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          >div {
            position: relative;
            flex: 1;
            height: .14rem;
            border-radius: .14rem
          }

          ;

          .bar-item {

            &::before {
              content: attr(data-value);
              font-size: .28rem;
              color: #32363e;
              position: absolute;
              top: calc(100% + .08rem);
              right: calc(100%);
              transform: translate(50%, 0);

            }

            ;

            &:first-child::before {
              content: "";
            }

            ;
          }

          ;

          .bar-pointer {
            position: absolute;
            width: .3rem;
            height: 0.3rem;
            border: 0.08rem solid #fff;

            &::before {
              content: attr(data-value);
              position: absolute;
              bottom: calc(100% + .1rem);
              left: 50%;
              transform: translate(-50%, 0);
              font-size: .32rem;
              font-weight: bold;
            }

            ;
          }

          ;
        }

        ;


      }

      ;

      .bar-tip {
        font-size: .28rem;
        color: #3B4549;
        line-height: .48rem;
      }

      ;
    }

    ;
  }

  ;


}
</style>