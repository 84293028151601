export const disRiskRanges = [
  {
    key: 'A01',
    name: '高血压',
    ranges: [0, 100, 240, 530],
  },
  {
    key: 'A02',
    name: '糖尿病',
    ranges: [0, 100, 240, 470],
  },
  {
    key: 'A03',
    name: '心肌梗塞',
    ranges: [0, 100, 150, 260],
  },
  {
    key: 'A04',
    name: '气喘',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A05',
    name: '痛风',
    ranges: [0, 100, 200, 300],
  },
  {
    key: 'A06',
    name: '胃溃疡',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A07',
    name: '癌症',
    ranges: [0, 100, 150, 220],
  },
  {
    key: 'A08',
    name: '甲状腺',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A09',
    name: '肝炎',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A10',
    name: '肝硬化',
    ranges: [0, 100, 200, 300],
  },
  {
    key: 'A11',
    name: '肾炎',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A12',
    name: '泌尿道结石',
    ranges: [0, 100, 350, 500],
  },
  {
    key: 'A13',
    name: '关节炎',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A14',
    name: '疼痛',
    ranges: [0, 100, 240, 600],
  },
  {
    key: 'A15',
    name: '肝炎',
    ranges: [0, 100, 150, 200],
  },
  {
    key: 'A16',
    name: '高血脂',
    ranges: [0, 100, 240, 600],
  },
  {
    key: 'A17',
    name: '脑中风',
    ranges: [0, 100, 600, 850],
  },
]
