<template>
  <div class="content">
    <van-nav-bar title="評估結果" left-arrow @click-left="$router.push('/user/history')" class="van-nav-bar-title" />
    <div class="result-content">
      <div class="user-base-info">
        <img class="report-img" src="../../assets/images/m22-report-img.png" alt="報告圖片" />
        <div class="info-title">個人基本信息</div>
        <div class="info-detail">
          <van-row class="info-detail-body" :gutter="16">
            <!-- <van-col :span="24">手機號：<span>--</span></van-col> -->
            <van-col :span="24" class="user-phone"><span></span></van-col>
            <van-col :span="10">性別：{{ userData.sex == 1 ? '男' : '女' }}</van-col>
            <van-col :span="14">出生日期：{{ userData.birth }}</van-col>
            <van-col :span="24">
              <van-divider dashed />
            </van-col>
            <van-col :span="24" class="info-detail-param">
              <van-row>
                <van-col :span="8" class="age">年齡：{{ userData.age || "--" }}歲</van-col>
                <van-col :span="8" class="height">身高：{{ userData.height || "--" }}cm</van-col>
                <van-col :span="8" class="weight">體重：{{ userData.weight || "--" }}kg</van-col>
                <van-col :span="12" class="waist">腰圍：{{ userData.waist || "--" }}cm</van-col>
                <van-col :span="12" class="hip">臀圍：{{ userData.hip || "--" }}cm</van-col>
                <van-col :span="12" class="hbp">收縮壓：{{ userData.sbp || "--" }}mmHg</van-col>
                <van-col :span="12" class="sbp">舒張壓：{{ userData.dbp || "--" }}mmHg</van-col>
              </van-row>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="user-exam-info">
        <div class="info-title">生化結果信息</div>
        <div class="info-detail">
          <div class="detail-table-tr detail-table-header">
            <div>主要檢查項目</div>
            <div>檢查結果</div>
            <div>與臨床標準比較</div>
            <div>與同類族群比較</div>
          </div>
          <div v-for="item in bioTableList" :key="item.id">
            <div class="detail-table-type">{{ item.name }}</div>
            <div class="detail-table-tr detail-table-td"
              :class="index % 2 === 0 ? 'detail-table-even' : 'detail-table-odd'" v-for="(bio, index) in item.children"
              :key="bio.key">
              <div>{{ getExamine(userData.examine, bio.key).item_d || "--" }}</div>
              <div class="font-color-red" v-if="getExamine(userData.examine, bio.key).cmp_n === '01'">
                {{ getExamine(userData.examine, bio.key).value || "--" }}
                {{ getExamine(userData.examine, bio.key).unit || "--" }}
              </div>
              <div v-else>
                {{ getExamine(userData.examine, bio.key).value || "--" }}
                {{ getExamine(userData.examine, bio.key).unit || "--" }}
              </div>
              <div class="font-color-red" v-if="getExamine(userData.examine, bio.key).cmp_n === '01'">
                {{ getExamine(userData.examine, bio.key).group_n || "--" }}
              </div>
              <div v-else>{{ getExamine(userData.examine, 'SBP').group_n || "--" }}</div>
              <div v-if="getExamine(userData.examine, bio.key).cmp_p === '01'">
                <div class="detail-bio-down font-color-red">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else-if="getExamine(userData.examine, bio.key).cmp_p === '05'">
                <div class="detail-bio-up font-color-red">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else-if="getExamine(userData.examine, bio.key).cmp_p === '08'">
                <div class="detail-bio-good font-color-green">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else>{{ getExamine(userData.examine,
                bio.key).group_p || "--" }}</div>
            </div>
          </div>
          <div class="detail-paragraph">
            生化檢驗結果反應了個人健康程度，但是健康程度又與年齡、性別有關，而且隨著時代與醫療技術的發展，同樣年齡段的人群，在時代不斷進步中，其身體的各項指標都在緩慢改變，現有的標準可能過一段時間便無法適用與同樣年齡段的人群。米因透過AI分析也不斷更新、完善資料，並提供同族群（People
            Like Me - 與你相似的人群 -
            與你同性別同年齡段的人群）的各項檢查值的平均數，以找出你需維持或改善的專案與目標，作為健康管理的參考依據。
          </div>
          <div class="detail-paragraph">
            本分析報告所採用的是米因全球領先的AI“四高引擎”演算法，主要針對：<br />
            1.
            四高高風險人群及時發現四高相關風險，包括：高血糖、高血壓、高血脂及高尿酸，為高風險人群提供疾病風險預警，提醒用户提早改善生活方式及飲食習慣、運動習慣，以達到預防目的；
            <br />2.
            四高已患病人群日常預防同四高緊密相關的心梗、腦中風、癌症等13種核心重症，以實現更好的生活、飲食同慢病管理。
          </div>
          <div class="detail-paragraph">
            往往許多表面看似正常的生化指標，通過米因領先“數智化”AI分析後，總會發現更多深層次健康問題或疾病風險，接下來請讓我們共同透過大數據AI的視角，層層深入，逐步揭示隱藏在常見數據之下的“健康密碼”。
          </div>
        </div>
      </div>
      <dis-chart v-for="disease in disRR" :key="disease.dis" :name="disease.devg" :ranges="disease.ranges"
        :value="disease.rate"></dis-chart>
      <!-- <div class="user-chart-info">
        <div class="info-title">
          透過醫療大數據+人工智能分析四高疾病潛在風險
        </div>
        <div class="info-chart">
          <list-chart :list="disListData1"></list-chart>
        </div>
      </div>
      <div class="user-chart-info">
        <div class="info-title">透過醫療大數據+人工智能分析癌症潛在風險</div>
        <div class="info-chart">
          <list-chart :list="disListData2"></list-chart>
        </div>
      </div>
      <div class="user-chart-info">
        <div class="info-title">
          透過醫療大數據+人工智能分析四高疾病潛在風險
        </div>
        <div class="info-chart">
          <list-chart :list="disListData3"></list-chart>
        </div>
      </div> -->

      <div class="user-sug-info">
        <div class="info-title">生活有道</div>
        <div class="info-sug">
          <div class="detail-paragraph">
            每個人的健康都需要日積月累，所以健康的身體離不開良好的生活習慣和心理素質，良好的生活習慣意味著合理地安排工作、休息、娛樂、科學的安排飲食等等。
          </div>
          <div class="life-sug">
            <div class="life-sug-box">
              <ol>
                <li>
                  合理膳食，多吃新鮮蔬菜和水果，多吃富含不飽和脂肪酸的食物。
                </li>
                <li>勤力動腦，多讀書，報賬等等，增著有益的閲讀習慣。</li>
                <li>培養有益於健康的興趣和嗜好。</li>
                <li>經常進行有益身心的運動。</li>
                <li>戒煙，有限度地飲酒。</li>
                <li>保證每天有平均 8 小時的睡眠時間。</li>
                <li>培養健康的心理素質。</li>
                <li>保持心平氣和，建立良好的家庭，鄰裏，同事的關係。</li>
                <li>順應自然規律，不反其道而行。</li>
                <li>
                  註意個人衞生，又遵守公共衞生和社會公德，為他人健康著想，因為一個漠視他人健康的人，不能稱為真正的健康人。
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="user-sug-info">
        <div class="info-title">人人運動身體好</div>
        <div class="info-sug">
          <div class="detail-paragraph">
            我們生活緊張，工作繁忙，加上科技進步，生活漸趨電子化，日常進行體力活動的機會越來越少，因此容易患上現代都市病，例如肥胖、糖尿病、高血壓、心臟病、腦血管病、部分癌症、失眠等。
          </div>
          <div class="detail-paragraph">
            其實，運動對健康有莫大益處。縱使生活忙碌，只需花點心思，便可在繁瑣的事務中騰出時間，每日累積做
            30分鐘
            運動。你可選擇早一點起牀，或利用午飯的時間，又或在晚飯前，從而改善健康狀況、提高工作效率。
          </div>
          <div class="life-sug">
            <div class="life-sug-box">
              只要因應個人的身體狀況，每日合共進行30分鐘運動，每次不少於10分鐘，持之以恆，會有以下益處：
            </div>
          </div>
          <div class="life-sug-extra">
            <ol>
              <li>
                1、合理膳食，多吃新鮮蔬菜和水果，多吃富含不飽和脂肪酸的食物。
              </li>
              <li>2、勤力動腦，多讀書，報賬等等，增著有益的閲讀習慣。</li>
              <li>3、培養有益於健康的興趣和嗜好。</li>
              <li>4、經常進行有益身心的運動。</li>
              <li>5、戒煙，有限度地飲酒。</li>
              <li>6、保證每天有平均 8 小時的睡眠時間。</li>
              <li>7、培養健康的心理素質。</li>
              <li>8、保持心平氣和，建立良好的家庭，鄰裏，同事的關係。</li>
              <li>9、順應自然規律，不反其道而行。</li>
              <li>
                10、註意個人衞生，又遵守公共衞生和社會公德，為他人健康著想，因為一個漠視他人健康的人，不能稱為真正的健康人。
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="user-sug-info">
        <div class="info-title">人人運動身體好</div>
        <div class="info-sug">
          <div class="detail-paragraph">
            隨著時間，我們的年齡漸漸長大，身體內的器官對各種營養物質的吸收能力就會下降。我們有時還會對吃喝往往採取將就，應付的態度，但是，生命是離不開營養的，失去營養的攝取，人的各種生理活動都將變得遲緩，停止，生命也就走到盡頭，而合理的營養攝取則能保證身體健康，延長壽命。
          </div>
          <div class="detail-paragraph">
            在膳食方面，現在人類發現了其中健康的秘密，粗細搭配，脂肪少，食肉不多，常吃豆類、水果、蔬菜、不吸煙、不飲酒或少飲酒等等
            ... 總之，健康長壽的膳食基本屬於均衡飲食，也就是進食與消耗互相平衡。
          </div>
          <div class="secondary-title">
            計劃踏向健康膳食路的人士，以下介紹膳食十則：
          </div>
          <div class="sug-list-box">
            <div class="sug-header">1. 合理搭配</div>
            <div class="sug-detail">
              要注意食物選擇，合理搭配副食，粗細兼顧，同時不要偏食，不拒食。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">2. 飲食清淡</div>
            <div class="sug-detail">
              過鹹、過甜、過辛辣的食品對人體有不利，清淡的食物可以有效地預防心腦血管疾病，腎臟和高血壓病的發生。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">3. 合理烹調</div>
            <div class="sug-detail">
              應採用煮、燉、熬、蒸等烹調方法，同時注意食物的色、香、味、形以刺激食慾。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">4. 飲食有節</div>
            <div class="sug-detail">
              忌暴飲暴食，尤其晚餐不宜過飽，忌過度飲酒，控制熱量和高脂肪食品的攝取。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">5. 多喝水</div>
            <div class="sug-detail">
              當血液黏滯度增加時，適量增加飲水能降低血液黏稠度，防止心腦血管疾病的發生。飲水
              200-300ml，每日飲6-8次。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">6. 保持適量蛋白質</div>
            <div class="sug-detail">
              蛋白質是人體生命活動的基礎物質，是人體組織的重要成分，於人體蛋白質每天都在消耗，所以每天攝入的蛋白質應要保持平衡。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">7. 適量補鈣</div>
            <div class="sug-detail">
              多吃鈣質豐富的食物，如牛奶、海帶、豆類食品及新鮮蔬菜和水果，對預防骨質疏鬆，預防貧血和降低膽固醇等都有很好的作用。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">8. 三餐求變</div>
            <div class="sug-detail">
              由於一日三餐的進餐時間不同，人體的生理過程在不斷變化，因此，每餐的食譜也應做相應的調整，例如：早餐堅持食用低糖、低脂食物，午餐以高蛋白質為重，晚餐以高糖，低蛋白質為主。只有如此，才能達到保障健康，延年益壽的目的。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">9. 四季求變</div>
            <div class="sug-detail">
              季節不同，天氣會有很大變化，人的生理和心理也會發生變化，因此，在不同的季節要吃不同的飯菜，例如：春季要多吃溫補陽氣之類的食物；夏季要多吃清熱祛火的食物，並註意補足水分和鈉、鉀、鈣、鎂等營養物質；秋季氣候乾燥，飲食要點先養陰潤肺；冬季天氣寒冷，飲食的要點先補陽益陰。
            </div>
          </div>
          <div class="sug-list-box">
            <div class="sug-header">10. 食水果的最佳時間</div>
            <div class="sug-detail">
              水果含有多重維他命、礦物質、糖分、纖維等人體必須的營養素，有益身體健康。但是，如何正確食水果能夠發揮其最大的功效，有人認為：「上午食的水果是金，中午食的水果是銀，黃昏食的水果是銅，晚上食的水果是鉛。」
              主要原因是上午食水果可以幫助消化吸收，有利通便，而水果的酸甜滋味能使人感到神清氣爽，如果入睡前食水果，不但影響消化，還會損害腸胃功能。
            </div>
          </div>
          <div class="sug-list-tip">
            <div class="list-tip-text">
              <div>特別説明：</div>
              <div>
                本AI分析所採用的是米因M22演算法引擎，本引擎不提供體重、體型、運動、飲食以及核心生化指標等“數智化”個人改善方案。
              </div>
              <div>
                若你對該部分內容感興趣，可選擇米因M20演算法引擎提供計算的其他套餐，詳情請流覽官網或諮詢香港體檢客服中心。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-sug-info">
        <div class="info-title">人人運動身體好</div>
        <div class="info-sug">
          <div class="detail-paragraph">
            進行身體檢查的時間要根據不同年齡、個人體質、性別、地域、家族病史、個人的經濟等等情況而定。一般而言，預防性健康體檢以成年人的整體健康狀況、經常復發病患或根據被檢者的工作環境、生活習慣和家族史等為關注重點做基本檢查，有以下建議：
          </div>
          <div class="exam-sug">
            <div>
              <div class="exam-sug-header">年齡 35 歲以下的人：</div>
              <div class="exam-sug-text">
                每兩年做一次檢查，近年高血壓、糖尿病、癌症等老年疾病有年輕化趨勢；
              </div>
            </div>
            <div>
              <div class="exam-sug-header">年齡 35 ~ 40 歲的人：</div>
              <div class="exam-sug-text">
                每年體檢一次，並且要增加腫瘤普查的內容；
              </div>
            </div>
            <div>
              <div class="exam-sug-header">年齡 40 ~ 50 歲的人：</div>
              <div class="exam-sug-text">
                每年至少體檢一次，並且要增加腫瘤普查的內容及心血管系統疾病的普查；
              </div>
            </div>
            <div>
              <div class="exam-sug-header">年齡 50 歲以上的人：</div>
              <div class="exam-sug-text">
                針對往年體檢結果，每年應體檢兩次以上，重點放在腫瘤以及心腦血管系統疾病的普查；
              </div>
            </div>
            <div>
              <div class="exam-sug-header">特殊人群：</div>
              <div class="exam-sug-text">
                應根據情況增加檢查項目或者調整體檢的時間；疾病高危區域應加強高發病的普查；家族高危病者，家族成員應加強高危病的普查。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-sug-info">
        <div class="info-title">常做的檢查項目</div>
        <div class="info-sug">
          <div class="exam-project">
            <span>膽固醇檢查：</span>在年齡 35
            歲的時候，做第一次血膽固醇檢查，以後每 1 年做一次；
          </div>
          <div class="exam-project">
            <span>子宮頸癌檢查：</span>女士有性行為以後開始做一次宮頸塗片檢查，以後每年檢查一次；
          </div>
          <div class="exam-project">
            <span>乳房檢查：</span>女士年齡 30
            歲的時候，由醫生進行第一次乳房檢查（平時應自檢）；
          </div>
          <div class="exam-project">
            <span>乳房造影檢查：</span>女士年齡 40
            歲或以上的時候，做第一次乳房造影檢查；
          </div>
          <div class="exam-project">
            <span>前列腺檢查：</span>男士年齡 40
            歲或以上的時候，做第一次前列腺檢查；
          </div>
          <div class="exam-project">
            <span>直腸鏡檢查：</span>年齡 50
            歲的時候，開始做第一次直腸鏡檢查，建議以後 3 ~ 5 年做一次。
          </div>
          <div class="exam-project">
            <span>深度AI體檢：</span>20-90歲人士，建議盡早做一次，全面瞭解健康狀況，深度發掘潛在問題，關注自身生命品質、疾病風險和預期醫療費用，獲取個性化改善方案與健康建議。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listChart from '@/components/custom/listChart'
import diseaseChart from '@/components/custom/diseaseChart'
import disChart from './charts/disChart.vue'
import Vue from 'vue'

import { getReportDetail } from '@/js/axios.js'

import { disRiskRanges } from './data'

import { Base64 } from 'js-base64'

import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  Loading,
  NumberKeyboard,
} from 'vant'

Vue.use(NavBar)
  .use(Progress)
  .use(Swipe)
  .use(SwipeItem)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Area)
  .use(DatetimePicker)
  .use(Cell)
  .use(CellGroup)
  .use(Loading)
  .use(NumberKeyboard)

export default {
  name: 'fourResult',
  components: {
    'list-chart': listChart,
    'disease-chart': diseaseChart,
    "dis-chart": disChart
  },
  data () {
    return {
      userData: {
        examine: []
      },
      diseaseType: 'gxy',
      id: '',
      disListData1: [],
      disListData2: [],
      disListData3: [],
      listRate1: [
      ],
      listRate2: [
      ],
      disList1: ["A01", "A02", "A16", "A05"],
      disList2: ["A07"],
      disList3: ["A14", "A12", "A10", "A03", "A15", "A04", "A11", "A07", "A13", "A08", "A09", "A06", "A17"],
      bioTableList: [
        {
          id: 1,
          name: "血壓",
          children: [
            {
              name: "收缩压",
              key: "SBP"
            },
            {
              name: "舒张压",
              key: "DBP"
            },
          ],
        },
        {
          id: 2,
          name: "血糖",
          children: [
            {
              name: "空腹血糖",
              key: "FG"
            },
            {
              name: "糖化血红蛋白",
              key: "HBA1C"
            },
          ],
        },
        {
          id: 3,
          name: "血脂肪",
          children: [
            {
              name: "总胆固醇",
              key: "CHOL"
            },
            {
              name: "低密度脂蛋白",
              key: "LDLC"
            },
            {
              name: "高密度脂蛋白",
              key: "HDLC"
            },
            {
              name: "甘油三酯",
              key: "TG"
            },
          ],
        },
        {
          id: 4,
          name: "腎功能",
          children: [
            {
              name: "尿酸",
              key: "UA"
            },
          ],
        },
      ],
      disRR: []

    }
  },
  created () {
    const id = this.$route.params.id
    if (id) {
      this.getResult(id)
    }
  },
  methods: {
    async getResult (id) {
      const result = await getReportDetail({ id })
      if (result.code === 200) {
        const data = JSON.parse(Base64.decode(result.data))
        this.userData = data

        this.setDisListData(data.disRR)
        this.listRate1 = data.disRR.filter(i => Number(i.rate) >= 200).map(i => ({
          key: i.dis,
          name: i.devg,
          value: Number(i.rate)
        })).sort((a, b) => b.value - a.value)
        this.listRate2 = data.disRR.filter(i => Number(i.rate) < 200).map(i => ({
          key: i.dis,
          name: i.devg,
          value: Number(i.rate)
        })).sort((a, b) => b.value - a.value)

        const arr = data.disRR.map(dis => {
          const d = disRiskRanges.find(i => i.key === dis.dis)
          if (d) {
            dis.ranges = d.ranges
          } else {
            dis.ranges = []
          }
          dis.rate = Number(dis.rate) || 0
          return dis
        })
        this.disRR = arr

      }
    },
    getExamine (examine, key) {
      const arr = examine.filter((i) => i.item === key)
      if (arr.length > 0) {
        return arr[0]
      }

      return ''
    },
    setDisListData (list) {
      this.disListData1 = this.setDataList(this.disList1, list)
      this.disListData2 = this.setDataList(this.disList2, list)
      this.disListData3 = this.setDataList(this.disList3, list)
    },
    setDataList (keyList,
      list
    ) {
      return keyList.map(item => {
        const el = list.find(i => i.dis === item)
        if (el) {
          return {
            key: el.dis,
            name: el.devg,
            ...this.getNumberRange(Number(el.rate))
          }
        }
      }).sort((a, b) => b.value - a.value)
    },
    getNumberRange (val) {
      if (val < 0) {
        return {
          HEA1: 0,
          HEA2: 0,
          HEA3: 0,
          value: 0
        }
      } else if (val >= 0 && val < 100) {
        return {
          HEA1: val,
          HEA2: 0,
          HEA3: 0,
          value: val
        }

      } else if (val >= 100 && val < 200) {
        return {
          HEA1: 100,
          HEA2: val - 100,
          HEA3: 0,
          value: val
        }
      } else {
        return {
          HEA1: 100,
          HEA2: 100,
          HEA3: val - 200,
          value: val
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.font-color-red {
  color: #fe3115;
  font-weight: 500;
}

.font-color-green {
  color: #04B959;
  font-weight: 500;
}

.user-phone {
  height: 30px;
}

.content {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.28rem;
  letter-spacing: 0;

  .van-nav-bar-title {
    flex: 0 0 auto;
  }

  .result-content {
    width: 100%;
    flex: 1;
    overflow: auto;
    font-size: 0.28rem;

    >div {
      position: relative;
      width: 100%;

      .detail-paragraph {
        color: #7d8799;
        margin: 0.16rem 0;
      }

      .info-title {
        font-size: 0.36rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-right: 0.12rem;

        &::before {
          content: '';
          width: 0.24rem;
          height: 0.2rem;
          border-top-right-radius: 0.2rem;
          border-bottom-right-radius: 0.2rem;
          background-color: #5272ff;
          margin-right: 0.2rem;
        }
      }

      .detail-tip {
        padding: 0 0.5rem 0.4rem 0;
        border: 0.04rem solid #ffa700;
        border-radius: 0.08rem;
        position: relative;
        width: calc(100% - 0.2rem);
        background-color: #fff;
        z-index: 2;
        left: 0.2rem;
        top: 0.2rem;

        >div {
          z-index: 10;
        }

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: -0.2rem;
          left: -0.2rem;
          z-index: -1;
          border: 0.04rem solid #5272ff;
          background-color: #fff;
        }
      }

      .secondary-title {
        font-weight: bold;
        color: #333;
        margin: 0.24rem 0;
      }
    }

    .user-base-info {
      padding-top: 1rem;
      color: #3b4549;

      .report-img {
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5rem;
        z-index: -1;
        background-image: linear-gradient(to bottom, #8097fd, #fff);
      }

      .info-detail {
        padding: 0.2rem;

        .info-detail-body {
          border: 0.12rem solid #8097fd;
          border-radius: 0.16rem;
          background-color: #fff;
          padding: 0.2rem 0.3rem;
          line-height: 0.6rem;

          .info-detail-param {

            .age,
            .weight,
            .height,
            .waist,
            .hip,
            .hbp,
            .sbp {
              position: relative;
              overflow: hidden;
              white-space: nowrap;
              padding: 0.2rem;
              background-color: #fbfdff;
            }

            .age,
            .weight,
            .height {
              border-top-right-radius: 0.12rem;
              border-top-left-radius: 0.12rem;
            }

            .age,
            .height {
              border-right: 0.02rem solid #cbd6dc !important;
            }

            .waist,
            .hip,
            .hbp,
            .sbp {
              border-top: 0.02rem solid #cbd6dc !important;
            }

            .waist,
            .hbp {
              border-right: 0.02rem solid #cbd6dc !important;
            }

            .age::after,
            .weight::after,
            .height::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 0.08rem;
            }

            .age::after {
              background-color: #52c95e;
            }

            .height::after {
              background-color: #efcb11;
            }

            .weight::after {
              background-color: #e98c00;
            }
          }
        }
      }
    }

    .user-exam-info {
      .info-detail {
        position: relative;
        width: 100%;
        padding: 0.24rem;

        .detail-table-header {
          background-color: #8097fd;
          height: 0.98rem;
          color: #fff;
        }

        .detail-table-td {
          height: 0.72rem;
          color: #3b4549;
        }

        .detail-table-even {
          background-color: #eff2ff;
        }

        .detail-table-tr {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 0.16rem;

          >div {
            flex: 3;
            padding: 0.06rem;

            &:nth-child(-n + 2) {
              flex: 4;
              padding-left: 0.12rem;
            }
          }
        }

        .detail-table-type {
          position: relative;
          width: 100%;
          height: 0.76rem;
          font-weight: bold;
          line-height: 0.76rem;
          padding-left: 0.12rem;
        }
      }
    }

    .user-chart-info {
      .info-chart {
        padding: 0.24rem;

        .chart {
          background-color: #f4f6fc;
        }
      }
    }

    .user-sug-info {
      .info-sug {
        padding: 0.24rem;

        .life-sug {
          position: relative;
          weight: 100%;
          border: 0.02rem solid #6883fb;
          padding: 0.12rem;
          color: #293030;

          .life-sug-box {
            position: relative;
            width: 100%;
            border: 0.02rem dashed #6883fb;
            padding: 0.24rem 0.3rem;

            li {
              margin-bottom: 0.24rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .life-sug-extra {
          padding: 0 0.3rem;

          >ol {
            border: 0.02rem solid #6883fb;
            border-top: none;
            padding: 0.3rem;
            border-bottom-left-radius: 0.12rem;
            border-bottom-right-radius: 0.12rem;

            li {
              margin-bottom: 0.24rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .sug-list-box {
          position: relative;
          width: 100%;

          .sug-header {
            color: #293033;
            padding: 0.16rem 0.2rem;
            background-color: #eff2ff;
            font-weight: bold;
          }

          .sug-detail {
            padding: 0.12rem 0.2rem;
            margin-bottom: 0.16rem;
            line-height: 0.4rem;
          }
        }

        .sug-list-tip {
          border: 0.02rem solid #cdcdcd;
          border-radius: 0.16rem;
          padding: 0.12rem;

          .list-tip-text {
            padding: 0.3rem 0.18rem;
            text-align: left;
            border: 0.02rem dashed #cdcdcd;

            >div {
              position: relative;
              width: 100%;
              margin-bottom: 0.2rem;

              &:first-child {
                font-weight: bold;
                text-align: center;
              }

              &:last-child {
                margin-bottom: 0rem;
              }
            }
          }
        }

        .exam-sug {
          padding: 0.2rem 0.4rem;
          border: 0.02rem solid #6883fb;
          border-radius: 0.16rem;
          display: flex;
          flex-direction: column;

          >div {
            width: 100%;
            position: relative;
            text-align: center;
            margin-bottom: 0.24rem;

            &:last-child {
              margin-bottom: 0;
            }

            .exam-sug-header {
              width: 100%;
              font-weight: bold;
              text-align: center;
            }

            .ex-sug-text {
              width: 100%;
              text-align: center;
              font-weight: normal;
            }
          }
        }

        .exam-project {
          position: relative;
          width: 100%;
          color: #3b4549;
          margin-bottom: 0.24rem;
          padding-left: 0.3rem;

          &::before {
            content: '';
            position: absolute;
            top: 0.14rem;
            left: 0;
            width: 0.16rem;
            height: 0.16rem;
            border-radius: 50%;
            background-color: #5272ff;
            flex: 0 0 auto;
            margin-right: 0.2rem;
          }

          &::last-child {
            margin-bottom: 0;
          }

          >span {
            font-weight: bold;
          }
        }
      }
    }


    .detail-bio-down,
    .detail-bio-up {
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        margin-left: .06rem;
        width: 0.18rem;
        height: .26rem;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
      }

      &::before {
        content: "*";
        position: absolute;
        right: calc(100% + 0.06rem);
        top: 0;
      }
    }

    .detail-bio-down {
      &::after {

        background-image: url(../../assets/images/report/bio-arrow-down.png);

      }
    }

    .detail-bio-up {



      &::after {

        background-image: url(../../assets/images/report/bio-arrow-up.png);

      }
    }


    .detail-bio-good {
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        margin-left: .06rem;
        width: 0.42rem;
        height: .38rem;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/report/bio-good.png);

      }
    }
  }
}
</style>
